$blue-100: #ecf0fa;
$blue-300: #017cee;
$blue-400: #0065c2;
$green-30: #bfe4da;
$green-300: #06ca92;
$gray-200: #c1c6cf;
$gray-400: #6c6e7a;
$gray-500: #e0e2e7;
$gray-800: #333333;

$pearl: #f9f9fa;
$black: #000000;
$white: #ffffff;
$green-400: #18b186;
$red: #fc5c4f;
$red-100: #fac3bf;
$red-300: #ffb8b2;
$gray-100: #ededed;
$activeState: #ecf0fa;
$deactivated: #cecdd2;
$tableHover: #f4f6fb;
$orange: #ff8300;

// does not exists
$gray: #ededed;
// $gray: #4b506d;

// overrides
$font-family-base: 'Open Sans';
$blue: #0082e0;
$green: #57c08e;
$cyan: #d3ddf0;
$yellow: #f9ebd0;
$yellow-100: #fad200;
$gray-100: #ededed;
$body-color: $gray-800;
$link-hover-color: $blue-400;

$primary: $blue-300;
$secondary: $gray-400;
$success: $green-300;
$danger: $red;
$light: #f9f9fa;
$dark: $gray-800;
$warning: #f5f1c4;
$table-hover-bg: $tableHover;
$input-color: $gray-800;
$font-size-lg: 1rem;
$input-placeholder-color: $gray-400;
$input-border-color: $gray-200;
$input-focus-border-color: $blue-300;
$small-font-size: 0.875rem;
// Breadcrumbs
$breadcrumb-font-size: 14px;
$breadcrumb-padding-y: 0.25rem !default;
$breadcrumb-padding-x: 0.25rem !default;
$breadcrumb-item-padding: 0.15rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-400;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;
