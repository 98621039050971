$datepicker__background-color: $white;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $blue-300 !default;
$datepicker__text-color: $gray-800 !default;
$datepicker__header-color: $gray-800 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;
$datepicker__border-radius: 10px;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.75rem !default;
$datepicker__font-family: $font-family-base;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

@import '../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import 'variables';

.react-datepicker {
  border: none;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  border-bottom: none;
  padding-top: 1rem;
}
.react-datepicker__current-month {
  background-color: $pearl;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.25rem 0rem;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.react-datepicker__navigation--previous {
  left: 1.5rem;
  top: 1.5rem;
  border-right-color: $gray-400;
}

.react-datepicker__navigation--next {
  right: 1.5rem;
  top: 1.5rem;
  border-left-color: $gray-400;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: normal;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &:hover {
    border-radius: 50%;
  }
  &:hover:not(.react-datepicker__day--selected):not(.react-datepicker__day--keyboard-selected) {
    background-color: $pearl;
    color: $gray-800;
  }

  &--highlighted {
    border-radius: 50%;
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: 50%;
  }

  &--keyboard-selected {
    border-radius: 50%;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $blue-100;
  color: black;

  &:hover {
    background-color: $blue-100;
  }
}

.react-datepicker__critical {
  display: flex;
  flex-direction: column;
}

.react-datepicker__critical-day {
  background-color: $red-300;
  &:hover {
    background-color: $red;
    color: white;
  }
}

.react-datepicker__critical-start-day {
  background-color: $red;
}

.react-datepicker__critical-warning-msg {
  display: none;
}

.show-warning-critical-msg {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
