@import 'variables';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'modal';
@import 'datepicker';
@import 'alerts';
@import 'chipstatus';
@import 'tables';
@import 'minialerts';
@import '../../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
@import 'react-virtualized';

body {
  letter-spacing: 0.2px;
}

.bg-tile {
  // background-image: url(./trx-spider.png);
  background-image: url(./sidebar-bg.png);
  background-repeat: repeat;
  background-size: 65px 43px;
  // background-size: 125px 83px;
  background-color: rgb(51, 51, 51);
}

.bg-pearl {
  background-color: $pearl !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-gray {
  color: $gray;
}

.text-gray-200 {
  color: $gray-200;
}

.text-gray-400 {
  color: $gray-400;
}

.text-gray-800 {
  color: $gray-800;
}

.text-yellow-100 {
  color: $yellow-100;
}

.text-orange {
  color: $orange;
}

.text-blue-400 {
  color: $blue-400;
}

.text-blue-300 {
  color: $blue-300;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.clickable-text {
  color: #017cee;
  cursor: pointer;

  &.text-gray {
    color: $gray-800;
  }

  &:hover {
    text-decoration-line: underline;
    color: #0065c2;
  }
}

.rounded-6 {
  border-radius: 6px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.trx-link {
  font-weight: bold;
  color: $gray-800;
  &:hover {
    color: $primary;
  }
}

.w-12rem {
  width: 12rem;
}

.w-14rem {
  width: 14rem;
}

.w-10rem {
  width: 10rem;
}

.w-9rem {
  width: 9rem;
}

.w-8rem {
  width: 8rem;
}

.h-0 {
  height: 0;
}

.breadcrumb {
  .breadcrumb-item {
    line-height: 19px;
    letter-spacing: 0.1px;
  }
}

.form-label,
.form-check-label {
  margin-bottom: 0;
  font-size: 14px;
}

.form-control:hover,
.form-control:active,
.form-control:focus,
.form-control.focus {
  border-color: $blue-300;
  + svg,
  svg {
    color: $blue-300;
  }
}

.modal-dialog .rbt-input-multi .close.rbt-close {
  font-size: 1rem;
  padding: 3px 7px;
}

//react-bootstrap-typeahead
.rbt {
  .rbt-input-hint-container:hover {
    > input {
      border-color: $blue-300;
      &:disabled {
        border-color: $gray-200;
      }
    }
    + svg {
      color: $blue-300;
    }
  }

  .rbt-input-main {
    padding-right: 3rem;
    text-overflow: ellipsis;
  }

  svg {
    position: absolute;
    top: 13px;
    right: 12px;
    color: $gray-400;
    pointer-events: none;
    cursor: pointer;
  }

  .rbt-input-multi + .rbt-aux {
    align-items: start;
    margin-top: 0.5rem;
  }

  &.is-invalid,
  &.is-valid {
    .rbt-aux {
      right: 15px;
    }
  }
}

.rbt-input.rounded {
  border-radius: 1.25rem !important;
}

.rbt-input-multi.form-control {
  height: auto !important;
  max-height: 200px;
  overflow: auto;
}

.rbt-menu {
  max-width: 100%;
}

.rbt-menu .dropdown-item {
  color: $gray-800;
  &:hover {
    background-color: $activeState;
  }
}

.text-green-400 {
  color: $green-400;
}

.text-green-300 {
  color: $green-300;
}

.pointer-events-none {
  pointer-events: none;
}

.highlight {
  background-color: $activeState;
  border-radius: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

input.form-control:disabled {
  background-color: $pearl;
  color: $gray-200;
  &::placeholder {
    color: $gray-200;
    opacity: 1;
  }
}

div.rbt-input-hint-container + div.rbt-aux {
  z-index: 0;
}

.photos-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.push-up {
  border-top: 2px dashed #0065c2 !important;
}

.push-down {
  border-bottom: 2px dashed #0065c2 !important;
}

.drag-arrow {
  transition: top 600ms ease;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.bg-active {
  background-color: $blue-100;
}

.bg-primary {
  background-color: $blue-300 !important;
}

.border-radius-8 {
  border-radius: 8px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $green-300;
  background-color: $green-300;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $gray;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $green-30;
  border-color: $green-30;
}

.react-datepicker-popper {
  z-index: 999;
}

// modal over popover
.modal-over-popover {
  z-index: $zindex-popover + 1;
}

// calendar view
#calendar-event-popover,
#popover-trx {
  box-shadow: 0px 2px 7px 0px #0000001f;
  border: none;
}

#popover-trx .arrow,
#warning-popover .arrow,
#calendar-event-popover .arrow {
  display: none;
}

.user-select-none {
  user-select: none;
}

.trx-tooltip {
  > .arrow {
    display: none;
  }

  > .tooltip-inner,
  > .popover-body {
    color: $gray-400;
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
    border: 1px solid $gray-100;
    font-size: 12px;
  }
  > .popover-body {
    font-size: 14px;
    border-radius: 8px;
  }
}

.trx-popover {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
  border: none;
  font-size: 14px;
  border-radius: 10px;

  > .arrow {
    display: none;
  }
  > .popover-body {
    color: $gray-400;
    background-color: $white;
    padding: 16px 20px;
    border-radius: 10px;
  }
}

#warning-popover {
  box-sizing: border-box;
  box-shadow: 0px 2px 7px 0px #0000001f;
  border: none;
  width: 324px;
  max-width: 324px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
}

.text-danger.muted {
  color: $red-300 !important;
}
