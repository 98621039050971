.trx-chipstatus {
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.03rem;
  border-radius: 0.5rem;
  border: none;
  text-transform: uppercase;
  padding: 0 0.4rem;
  height: 2rem;
  background: $gray;

  &:not(.chipstatus-lg) {
    width: 9.375rem;
  }

  &.normal {
    color: $gray-400;
    box-shadow: inset 0px 1px 2px $deactivated;

    &.outline {
      color: $gray-200;
      box-shadow: none;
      border: solid 1px $gray-200;
    }
  }

  &.green {
    color: $green-400;
    box-shadow: inset 0px 1px 2px $deactivated;

    &.outline {
      background: $white;
      color: $green-300;
      box-shadow: none;
      border: solid 1px $green-300;

      &.dropdown:hover {
        border-color: $blue-300;
      }
    }
  }

  &.red {
    color: $red;
    background: $white;
    border: solid 1px $red;

    &.dropdown:hover,
    &.dropdown:focus {
      border-color: $blue-300;
    }
  }

  &.blue {
    color: $blue-300;
    background: $white;
    border: solid 1px $blue-300;
  }

  &.yellow {
    color: $gray-800;
    background: $warning;
  }

  &.dropdown:hover,
  &.dropdown:focus {
    background-color: $activeState;
  }
}

.mini-chipstatus .trx-chipstatus,
.mini-chipstatus.trx-chipstatus {
  box-shadow: none;
  font-size: 12px;
  height: 1.5rem;
  margin-top: 1.6rem;
  margin-bottom: 0.5rem;

  svg {
    display: none;
  }

  &.normal {
    background: transparent;
  }

  &:hover:not([disabled]) {
    background-color: $activeState;
  }
}

.mini-chipstatus.trx-chipstatus {
  margin-top: 0px;
  margin-bottom: 26px;
}
