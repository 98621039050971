// Modal
.modal-dialog {
  .modal-header {
    padding-left: 2.5rem;
    padding-top: 2.25rem;
    .modal-title {
      font-size: 1.5rem;
      margin-top: 0.875rem;
      font-weight: bold;

      svg {
        margin-right: 0.5rem;
      }
    }
    i.fas {
      font-size: 1.125rem;
    }
  }
  .modal-body,
  .modal-footer {
    padding-left: 2.8125rem;
  }
  .modal-body,
  .modal-footer {
    padding-right: 2.375rem;
  }

  .modal-content {
    border-radius: 1rem;
  }
  .modal-footer {
    padding-bottom: 1.875rem;
  }
  form {
    .modal-body {
      padding-bottom: 0;
    }
    .modal-footer {
      padding-top: 0;
    }
  }
  .close {
    font-size: 2rem;
    padding: 0.5rem 2rem;
  }

  &.mw-600 {
    max-width: 640px !important;
  }

  &.width-600 {
    max-width: 600px;
  }
}
