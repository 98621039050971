.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  border-radius: 0.25rem;
}
.editor:hover {
  border-color: #017cee;
}

.editor:focus-within {
  border-color: #017cee;
  box-shadow: 0 0 0 0.2rem rgb(1 124 238 / 25%);
}

.invalid,
.invalid:hover {
  border: 1px solid #fc5c4f;
}

.invalid:focus-within {
  border: 1px solid #fc5c4f;
  box-shadow: 0 0 0 0.2rem rgb(252 92 79 / 25%);
}

.editor :global(.public-DraftEditor-content) {
  max-height: 96px;
  height: 96px;
  overflow-y: scroll;
}

/* MENTIONS */

.mention {
  font-weight: bold;
}

.mentionSuggestions {
  border: 1px solid #c1c6cf;
  background: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  width: 400px;
  z-index: 1;
  max-height: 250px;
  overflow-y: scroll;
}

.mentionSuggestionsEntryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.mentionSuggestionsEntry {
  padding: 9px 15px 9px 6px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #ecf0fa;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
  color: #a7a7a7;
}
