.alert {
  color: $dark;
  border-radius: 0.3rem;

  &.alert-primary {
    background-color: #cddcf3;
    border-color: #cddcf3;
    hr {
      border-top-color: darken(#cddcf3, 5%);
    }
    .alert-link {
      color: #017cee;
    }
  }

  &.alert-success {
    background-color: #bfe4da;
    border-color: #bfe4da;
    hr {
      border-top-color: darken(#bfe4da, 5%);
    }
    .alert-link {
      color: #017cee;
    }
  }

  &.alert-danger {
    background-color: #ffb8b3;
    border-color: #ffb8b3;
    hr {
      border-top-color: darken(#ffb8b3, 5%);
    }
    .alert-link {
      color: #017cee;
    }
  }

  &.alert-warning {
    background-color: #f5f1c4;
    border-color: #f5f1c4;
    hr {
      border-top-color: darken(#f5f1c4, 5%);
    }
    .alert-link {
      color: #017cee;
    }
  }
}
