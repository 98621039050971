#mini-alert {
  // // bootstrap $zindex-popover: 1070;
  z-index: 1070;
  display: flex;
  flex-direction: column;
  margin: 24px;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.mini-alert {
  color: $dark;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $gray-100;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  position: relative;
  transition: opacity 500ms;
  opacity: 0;

  @media (min-width: 768px) {
    &.size-s {
      width: 340px;
    }

    &.size-m {
      width: 480px;
    }

    &.size-l {
      width: 645px;
    }

    &.size-xl {
      width: 740px;
    }
  }

  @media (min-width: 992px) {
    &.size-xl {
      width: 820px;
    }
  }

  &.is-open {
    opacity: 1;
  }

  .text-warning {
    color: $yellow-100 !important;
  }

  > svg {
    position: absolute;
    font-size: 18px;
    top: 24px;
    left: 20px;
  }

  > p {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  > button.close {
    position: absolute;
    top: 0.5rem;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
    font-size: 18px;
  }

  span.bevel {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 6px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &.bg-success {
      background-color: #bfe4da !important;
    }

    &.bg-primary {
      background-color: #cddcf3 !important;
    }

    &.bg-danger {
      background-color: #ffb8b3 !important;
    }

    &.bg-warning {
      background-color: #f5f1c4 !important;
    }
  }
}

.cookie-alert {
  strong {
    white-space: nowrap;
  }

  > svg {
    margin-left: -20px;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    strong {
      white-space: normal;
    }
  }
}
